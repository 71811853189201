<template>
	<v-container fluid>
		<v-card>
			<v-card-text>
				<crud
					:model="'Charger'"
					create-permission="Agregar Cargos"
					edit-permission="Editar Cargos"
					delete-permission="Eliminar Cargos"
					resource="charges"
					load="horario"
					show-clone
					bulk-actions
				>
				</crud>
			</v-card-text>
		</v-card>
	</v-container>
</template>
<script>
import model from "@/models/concepts";
const { headers, filters, load, defaultOrder } = model;
export default {
	beforeMount() {},
	data() {
		return {
			item: null,
			headers,
			filters,
			load,
			defaultOrder,
		};
	},
};
</script>
